import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import {
  SeoTextSection,
  SeoTextHeading,
  SeoTextContent,
} from '../../components/SeoText';
import CTA from '../../components/CTA';

const CriticalWritingServicePage = () => (
  <Layout
    pageWrapperClassName="critical-writing-service-page"
    headerClassName="header_white-bg"
  >
    <Helmet
      title="Critical Essay Writing Service to Ensure Your Academic Success | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content:
            'Have a look at the major advantages students can get if they deal with TakeAwayEssay.com. Request affordable custom critical essays right now to get the best grades and improve your rating.',
        },

        {
          property: 'og:title',
          content:
            'Buy Critical Essay Online and Overcome Your Academic Problems | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content:
            'If you’re determined to buy critical essay online, read this page because it proves that our quality and multiple advantages guarantee the best academic results for all students. If you place an order on our website, you receive on-time papers of the highest quality.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: 'https://takeawayessay.com/open-graph-img.jpg',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: 'https://takeawayessay.com/critical-writing-service.html',
        },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Originality is
        <br className="first-screen-section__title__br" /> critically important
        <br className="first-screen-section__title__br" /> in academic writing
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        This is why every paper
        <br className="first-screen-section__heading__br" /> you buy from us is
        unique.
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Critical essay writing service to reduce your stress and ensure success
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            While students learn, they may face a great variety of learning
            obstacles that may spoil their nervous system a lot. They may suffer
            because of strict demands or educators, too short deadlines, and too
            complicated subjects. Finally, many of them suffer because of at
            least one piece of writing that is hard for them. One of such papers
            may be a critical essay. It’s not easy to dig into the root of the
            problem, analyze it, and explain it on paper. As a result, many
            youngsters seek our aid.
          </p>
          <p>
            Our custom critical essay writing service is known as
            TakeAwayEssay.com. We are in this industry for many consecutive
            years to help all students who face this or that learning issue. A
            critical essay is one of them. Yet, we offer a lot more. Therefore,
            continue to read this landing page to find out more about the
            advantages we propose.
          </p>
          <h2 className="h3">
            Use a professional critical thinking essay writing service
          </h2>
          <p>
            If you really need professional Critical Essay Writing Help,
            TakeAwayEssay.com is the perfect place to make all of your learning
            dreams come true. We hire only certified and skilled writers who
            know how to match the top standards of any educational institution.
            They sharpen their skills regularly and are aware of the latest
            editions to the demands of academic writing, citing, etc.
          </p>
          <p>
            When you hire a critical essay writer on our website, you enjoy all
            kinds of academic features. Your order can be written, quoted,
            outlined, rewritten, proofread, researched, edited, and so on. As we
            have over 500 experts, you will easily find a perfect match for your
            case. Our helpers specialize in:
          </p>
          <ul className="dot-list">
            <li>Literature;</li>
            <li>English;</li>
            <li>Math;</li>
            <li>Algebra;</li>
            <li>Geometry;</li>
            <li>Web design;</li>
            <li>Technology;</li>
            <li>Marketing;</li>
            <li>Finance;</li>
            <li>Business;</li>
            <li>Music, etc.</li>
          </ul>
          <p>
            They easily help with writing critical thinking essays, as well as
            other vital assignment types. These are book reviews, movie reports,
            personal statements, narrations, case studies, dissertations, term
            papers, and others.
          </p>
          <h2 className="h3">Request only affordable custom critical essays</h2>
          <p>
            You surely know that custom critical essays are not given for free.
            You will have to spend your money because professional aid has its
            price. It’s vital to find a fair platform that charges enough.
            TakeAwayEssay.com is that very platform. The prices we set are
            affordable and fair. How much will be paid for our aid? It depends
            only on your preferences, and it’s a bottle of wine for any student.
          </p>
          <p>
            When you place an order, you are supposed to let us know what must
            be done. Provide the details in the application form. It contains
            the next points:
          </p>
          <ul className="dot-list">
            <li>
              Assignment type – essay, book report, term paper, coursework, etc.
            </li>
            <li>Size – words, characters, or pages.</li>
            <li>Desired quality – school, college, or university.</li>
            <li>Urgency – hours, days, or weeks.</li>
          </ul>
          <p>
            An online calculator shows instant results. If the proposal exceeds
            your finances, do not worry. You can alter any of these details to
            your liking. For example, you may shorten the volume of the paper to
            make it cheaper. You need to spend some 2 minutes or so to
            understand how you can impact the price and select the most suitable
            proposal for you.
          </p>
          <h2 className="h3">Other privileges to count on</h2>
          <p>
            When you order critical essays or other pieces of writing on our
            custom platform, you also enjoy other vital dividends. They are as
            important as high quality or affordable prices. Why are so
            confident? Just check the next points and you will instantly realize
            that we are correct.
          </p>
          <h3 className="h3">On-time aid</h3>
          <p>
            We guess you value your time a lot. You want to submit your
            assignments on time and be able to undertake some non-academic tasks
            as well. We can help you to optimize your routine. When you place an
            order on our site, you will surely meet all your timeframes.
          </p>
          <p>
            We are a team of professionals. Every writer has outstanding
            abilities that are successfully applied when it’s necessary to
            complete any order. We easily meet the shortest deadlines. Just tell
            what must be done and when. Your writer will realize what method
            should be applied instantly. Thus, you will never be late with us.
          </p>
          <h3 className="h3">100% authentic papers</h3>
          <p>
            It is not easy to create original and readable texts. Many students
            struggle because of this demand. They mess up various formats,
            cannot insert citations correctly, or use words that spam the text.
            Thus, they need our authentic aid. We can assure every client that
            his or her academic project will be unique and readable.
          </p>
          <p>
            Our writers and editors are true experts. They know how to avoid
            plagiarism and replace outdated words with a modern and unique
            lexicon. They utilize dependable checking tools to detect the
            slightest traces of plagiarism. If any signs are detected, be sure
            your writer will replace them with unique content.
          </p>
          <p>
            We also help with formatting your title page and other pages
            according to the most popular writing styles, as well as inserting
            quotations. You may freely count on MLA, Chicago, Turabian, APA, and
            others.
          </p>
          <h3 className="h3">Full confidentiality</h3>
          <p>
            We are a respectful writing platform that protects the private data
            of all its customers. We never disclose any details about you and
            your orders to anybody else. A great antivirus application guards
            our databases 24/7.
          </p>
          <h3 className="h3">Hourly supervision</h3>
          <p>
            You can access our online platform whenever you want because we
            operate 24/7. Thus, you can place instant orders or ask vital
            questions. Our technicians are always ready to provide you with the
            necessary clarifications.
          </p>
          <h3 className="h3">A Refund Guarantee</h3>
          <p>
            We also offer refunds to protect your investments. After your writer
            accepts your conditions, he/she pledges to fulfill them all. If
            he/she fails you and makes some mistakes, we will return all your
            investments.
          </p>
          <p>
            As you can see, TakeAwayEssay.com provides all the necessary
            conditions and guarantees to ensure your success, safety, and
            comfort. No matter what challenge you face, our competent writers
            will complete it flawlessly. No matter what question you have, our
            consultants will clarify it. No matter what deadline you have, our
            writers will meet it. Everything you may need is ensured by us. So,
            don’t delay and place an instant order right now!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Say “No” to copy-pasted essays from the Internet.
      <br /> Buy plagiarism-free papers.
    </CTA>
  </Layout>
);

export default CriticalWritingServicePage;
